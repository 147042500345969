<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('projects.general.projects')"
          :create-button="$can(['projects.create.*']) ? $t('create_project') : ''"
          @new-item="$emit('new-item')"
        />
      </template>

      <template v-slot:item.title="{ item }">
        <router-link :to="`/project/${item.id}`" @click.stop>
          {{ item.title }}
        </router-link>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          v-if="$can(['projects.create.*'])"
          :actions="actions"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        />
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';

export default {
  name: 'ProjectTable',

  components: {
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('project'),
          value: 'title',
        },
        {
          text: this.$t('estimated_beginning_at'),
          value: 'estimated_beginning_at',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('deadline'),
          value: 'deadline',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('status'),
          value: 'statusLabel',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('hours_spent'),
          value: 'total_time_spent',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
        },
      ],
    };
  },

  computed: {
    decoratedRows() {
      return this.rows.map(r => ({
        ...r,
        statusLabel: this.$t(`project_statuses.${r.status}`),
        clientName: r.client?.name,
      }));
    },
  },
};
</script>
