import http from './http';

const endpoint = 'api/clients';

const contactService = {
  model: 'contact',

  getPage: (pageNum, params) => http.get(`${endpoint}?page=${pageNum}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: contact => http.post(`${endpoint}`, contact),

  update: contact => http.put(`${endpoint}/${contact.id}`, contact),

  delete: contact => http.delete(`${endpoint}/${contact.id}`),
};

export default contactService;
