<template>
  <div class="page-wrapper">
    <ProjectTable
      :loading="isDataLoading"
      :pagination="projectPagination"
      :rows="projectArray"
      @delete="crudMixin_delete(onDelete, 'project', $event)"
      @edit="crudMixin_openForm('project', $event)"
      @new-item="crudMixin_openForm('project', newProjectTemplate)"
    />

    <v-dialog
      v-model="isProjectFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <ProjectForm
        :dialog="isProjectFormOpen"
        :form-item="projectFormItem"
        @create="crudMixin_created('project', $event)"
        @update="crudMixin_updated('project', $event)"
        @cancel="isProjectFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import ProjectForm from '../components/forms/ProjectForm';
import ProjectTable from '../components/tables/ProjectTable';
import crudMixin from '../mixins/crud-mixin';
import projectService from '../api/project-service';

export default {
  name: 'Projects',

  components: {
    ProjectForm,
    ProjectTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      projectArray: [],
      projectPagination: {
        page: 1,
      },
      newProjectTemplate: {
        estimated_beginning_at: format(new Date(), 'yyyy-MM-dd'),
        assignees: [],
        status: 'planned',
      },
      projectFormItem: {},
      projectFilterParams: '',
      isProjectFormOpen: false,

      isDataLoading: true,
      onDelete: projectService.delete,
    };
  },

  created() {
    this.getProjects(1);
  },

  methods: {
    getProjects(pageNum) {
      this.crudMixin_getPage(
        projectService.getPage,
        projectService.model,
        pageNum,
        this.projectFilterParams,
      );
    },
  },
};
</script>
