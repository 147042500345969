<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="project.title"
              :error-messages="errors.title"
              :label="formMixin_getRequiredFieldLabel($t('title'))"
              @blur="formMixin_clearErrors('title')"
            />
          </v-col>
<!--          <v-col cols="12" sm="6">-->
<!--            <BaseAutocomplete-->
<!--              v-model="project.client"-->
<!--              :item="project.client"-->
<!--              :search-function="contactSearchFunction"-->
<!--              :error-messages="errors.client_id"-->
<!--              :label="$t('client')"-->
<!--              clearable-->
<!--              @blur="formMixin_clearErrors('client_id')"-->
<!--              @input="formMixin_setAutocompleteValue($event, project, 'client')"-->
<!--            />-->
<!--          </v-col>-->
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="project.estimated_beginning_at"
              :error-messages="errors.estimated_beginning_at"
              :label="$t('estimated_beginning_at')"
              @blur="formMixin_clearErrors('estimated_beginning_at')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="project.deadline"
              :error-messages="errors.deadline"
              :label="$t('deadline')"
              @blur="formMixin_clearErrors('deadline')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="project.estimated_duration"
              :error-messages="errors.estimated_duration"
              :label="$t('estimated_duration')"
              @blur="formMixin_clearErrors('estimated_duration')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="project.estimated_sum"
              :error-messages="errors.estimated_sum"
              :label="$t('estimated_sum')"
              type="number"
              lang="lt"
              @blur="formMixin_clearErrors('estimated_sum')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="project.status"
              :error-messages="errors.status"
              :items="statuses"
              :label="$t('status')"
              @blur="formMixin_clearErrors('status')"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="project.description"
              :error-messages="errors.description"
              :label="$t('description')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('description')"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="form-group-header" cols="12">
            <h3 class="text-h6">{{ $t('assign_users') }}</h3>
          </v-col>
        </v-row>
        <v-row
          v-for="(assignee, index) of project.assignees"
          :key="index"
          dense
        >
          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="assignee.user"
              :disabled="assignee.permission === 'creator'"
              :error-messages="errors[`assignees.${index}.user_id`]"
              :item="assignee.user"
              :label="$t('user')"
              :search-function="userSearchFunction"
              item-text="full_name"
              item-value="user_id"
              clearable
              @input="formMixin_setAutocompleteValue($event, assignee, 'user')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              :value="assignee.permission"
              :items="permissionTypes"
              :disabled="assignee.permission === 'creator'"
              :error-messages="errors[`assignees.${index}.permission`]"
              :label="$t('permissions.label')"
              @change="setPermission($event, index)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              text
              @click="addAssignee"
            >
              {{ $t('add_user') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span v-if="!project.id" class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
/* eslint-disable no-param-reassign */
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import projectService from '../../api/project-service';
import contactService from '../../api/contact-service';
import BaseAutocomplete from '../base/BaseAutocomplete';
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import userService from '../../api/user-service';

export default {
  name: 'ProjectForm',

  components: { BaseDatepickerInput, BaseAutocomplete },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      project: {},
      contactSearchFunction: contactService.search,
      userSearchFunction: userService.search,
      statuses: [
        { value: 'planned', text: this.$t('project_statuses.planned') },
        { value: 'active', text: this.$t('project_statuses.active') },
        { value: 'support', text: this.$t('project_statuses.support') },
        { value: 'ended', text: this.$t('project_statuses.ended') },
      ],
      permissionTypes: [
        { text: this.$t('project_permissions.creator'), value: 'creator', disabled: true },
        { text: this.$t('project_permissions.read'), value: 'read' },
        { text: this.$t('project_permissions.write'), value: 'write' },
      ],
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.project.id ? 'edit_project' : 'new_project');
    },
  },

  methods: {
    onDialogOpen() {
      const project = JSON.parse(JSON.stringify(this.formItem));
      project.assignees = project.assignees.map((a) => {
        const user = {
          id: a.user_id,
          full_name: a.personal_data?.full_name,
        };
        delete a.personal_data;
        return {
          ...a,
          user,
        };
      });
      this.project = project;
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(projectService, this.project);
    },

    addAssignee() {
      this.project.assignees.push({ permission: 'write' });
    },

    setAssignee(assignee, index) {
      if (assignee) {
        const { permission } = this.project.assignees[index];
        assignee.user_id = assignee.id;
        this.project.assignees[index] = assignee;
        if (assignee.client_id) {
          assignee.permission = 'read';
        } else {
          assignee.permission = permission;
        }
        this.project.assignees.splice(index, 1, assignee);
      } else {
        this.project.assignees[index] = {};
      }
    },

    setPermission(value, index) {
      this.project.assignees[index].permission = value;
    },
  },
};
</script>
