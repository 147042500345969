<template>
  <v-autocomplete
    :value="value"
    :search-input.sync="query"
    :error-messages="errorMessages"
    :loading="isDataLoading"
    :disabled="disabled"
    :hint="!query || query.length < 3 ? $t('enter_three_or_more_symbols') : ''"
    :label="label"
    :item-text="itemText"
    :items="items"
    :item-value="itemValue"
    :clearable="clearable"
    :prepend-icon="prependedIcon"
    :append-icon="appendedIcon"
    :hide-details="hideDetails"
    hide-no-data
    hide-selected
    no-filter
    return-object
    @blur="$emit('blur', $event)"
    @click:append="appendedIconClicked"
    @click:clear="$emit('input', null)"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'BaseAutocomplete',

  props: {
    label: {
      type: String,
      default: '',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    value: {
      type: [Object, null],
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    appendedIcon: {
      type: String,
      default: '',
    },

    prependedIcon: {
      type: String,
      default: 'mdi-magnify',
    },

    searchFunction: {
      type: Function,
      required: true,
    },

    hideDetails: Boolean,
  },

  data() {
    return {
      items: [],
      isDataLoading: false,
      query: '',
      timerID: null,
    };
  },

  watch: {
    query(val) {
      clearTimeout(this.timerID);
      if (!val) {
        this.items = [];
        return;
      }
      if (val.length < 3) {
        return;
      }
      if (this.value && val === this.value[this.itemText]) {
        return;
      }
      this.timerID = setTimeout(() => {
        this.getItems(val);
      }, 500);
    },

    value(val) {
      if (!val) {
        return;
      }
      for (let i = 0; i < this.items.length; i++) {
        // TODO support nested itemValue properties
        if (this.items[i][this.itemValue] === val[this.itemValue]) {
          return;
        }
      }
      this.items.push(val);
    },
  },

  created() {
    if (this.value) {
      this.items.push(this.value);
    }
  },

  methods: {
    onInput(val) {
      setTimeout(() => {
        // ensures that backend is not being queried with value that was selected
        clearTimeout(this.timerID);
      });
      this.$emit('input', val);
    },

    async getItems(v) {
      this.isDataLoading = true;
      try {
        const res = await this.searchFunction(v);
        this.items = res.data.data ? res.data.data : res.data;
      } catch (e) {
        this.items = [];
      }
      this.isDataLoading = false;
    },

    appendedIconClicked() {
      this.$emit('click:append');
    },
  },
};
</script>
