import http from './http';

const endpoint = 'api/projects';

const projectService = {
  model: 'project',

  getPage: (pageNum, params) => http.get(`${endpoint}?page=${pageNum}`, { params }),

  create: project => http.post(`${endpoint}`, project),

  update: project => http.put(`${endpoint}/${project.id}`, project),

  delete: project => http.delete(`${endpoint}/${project.id}`),
};

export default projectService;
